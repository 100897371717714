import * as React from 'react';
import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

import AnimatedLogo from './AnimatedLogo';

const Hero: React.FC = (): JSX.Element => {
  return (
    <Flex w="100%" minH="80vh" alignContent="center">
      <Grid templateColumns={'repeat(6, 1fr)'} gap={6}>
        <GridItem colSpan={[6, 4]} display="flex" alignItems="center">
          <Stack>
            <Heading
              as="h1"
              fontSize={['5xl', '8xl']}
              color={useColorModeValue('cyan.400', 'cyan.600')}
              mb="-6"
            >
              Hello there!
            </Heading>
            <Heading
              as="h2"
              fontSize={['5xl', '8xl']}
              color={useColorModeValue('cyan.600', 'cyan.300')}
            >
              I'm Ali Darab
            </Heading>
            <Text as="p" fontSize="2xl">
              I'm a <Text as="b">Full-Stack Web Developer</Text> who is deeply
              passionate about developing exceptional websites that provide
              intuitive, pixel-perfect user experiences with efficient and
              modern back-ends.
            </Text>
          </Stack>
        </GridItem>
        <GridItem
          colSpan={[6, 2]}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AnimatedLogo />
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default Hero;
