import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Box, Stack, Text, Icon } from '@chakra-ui/react';
import { FaGithub } from '@react-icons/all-files/fa/FaGithub';
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';

const Footer: React.FC = (): JSX.Element => {
  return (
    <Box as="footer" py={12} textAlign="center">
      <Stack>
        <Text>
          &copy; Copyright {new Date().getFullYear()} -{' '}
          <OutboundLink
            href="https://www.github.com/alidarab/website"
            target="_blank"
            rel="noopener noreferrer"
          >
            Designed and Developed
          </OutboundLink>{' '}
          by Ali Darab
        </Text>
      </Stack>
      <Stack direction="row" spacing="4" justifyContent="center" pt="6">
        <OutboundLink
          href="https://www.github.com/alidarab"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={FaGithub} />
        </OutboundLink>
        <OutboundLink
          href="https://www.linkedin.com/in/alidarab"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={FaLinkedinIn} />
        </OutboundLink>
        <OutboundLink
          href="https://www.twitter.com/alidarab"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon as={FaTwitter} />
        </OutboundLink>
      </Stack>
    </Box>
  );
};

export default Footer;
