import * as React from 'react';
import { graphql, PageProps, HeadProps } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import { Seo } from '../components/Seo';
import About from '../components/About';

const Index = ({ data }: PageProps<Queries.IndexQuery>): JSX.Element => (
  <Layout>
    <Hero />
    <About />
  </Layout>
);

export default Index;

export function Head(props: HeadProps<Queries.IndexQuery>) {
  return (
    <Seo
      title={`${props.data.site?.siteMetadata.title} | ${props.data.site?.siteMetadata.description}`}
      description={props.data.site?.siteMetadata.description}
      pathname="/"
    >
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "email": "alidarab@live.com",
            "jobTitle": "Full-Stack Web Developer",
            "name": "Ali Darab",
            "url": "http://www.alidarab.com",
            "sameAs" : [ "https://www.facebook.com/alidarab",
            "https://www.linkedin.com/in/alidarab",
            "http://twitter.com/alidarab",
            "http://instagram.com/thedarab"]
          }
        `}
      </script>
    </Seo>
  );
}

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
