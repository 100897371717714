import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import {
  Container,
  Heading,
  Stack,
  Text,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import SkillList from './SkillList';

const About = () => {
  return (
    <Container maxW="container.xl" minH="80vh" id="about">
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={6}>
        <GridItem display="flex">
          <Stack alignSelf="center">
            <Heading as="h3" fontSize={['2xl', '6xl']}>
              About Me
            </Heading>
            <Text>
              Hi, my name is Ali and I enjoy coding and building websites.
              Having worked in visual, print, UI/UX design, advertising, and
              even video production, I’ve found web development is where I feel
              truly at home, where I'm able to fully utilize my diverse
              professional background to develop robust and scalable solutions
              for the modern web.
            </Text>
            <Text>
              Currently, I lead a team of talented Web Developers at{' '}
              <OutboundLink
                href="https://www.venasolutions.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vena Solutions
              </OutboundLink>
              , building accessible, performant digital experiences for{' '}
              <OutboundLink
                href="https://www.venasolutions.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                prospects
              </OutboundLink>
              ,{' '}
              <OutboundLink
                href="https://www.lifeatvena.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                talent
              </OutboundLink>{' '}
              and{' '}
              <OutboundLink
                href="https://www.plantogrow.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                finance professionals
              </OutboundLink>
              , and much more. Here are a few technologies I’ve been working
              with recently.
            </Text>
            <SkillList />
          </Stack>
        </GridItem>
        <GridItem display="flex" justifyContent="center" alignItems="center">
          <StaticImage
            src="../images/alidarab.png"
            alt="Ali Darab"
            placeholder="blurred"
            layout="constrained"
            width={470}
            height={592}
          />
        </GridItem>
      </Grid>
    </Container>
  );
};

export default About;
