import * as React from 'react';
import { motion, AnimationProps } from 'framer-motion';

const urduLogoProps: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 1, ease: 'linear', delay: 1.5 },
};

const enLogoProps: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 1, delay: 0.9, ease: 'easeIn' },
};

const leftCurlyProps: AnimationProps = {
  initial: { opacity: 0, x: 200 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: 'easeInOut' },
};

const rightCurlyProps: AnimationProps = {
  initial: { opacity: 0, x: -200 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: 'easeInOut' },
};

const AnimatedLogo: React.FC = () => {
  return (
    <motion.svg
      width="491"
      height="242"
      viewBox="0 0 491 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        fill="#00A3C4"
        {...urduLogoProps}
        d="M295.491 79.6137V112.802L257.385 112.87C252.651 112.87 248.963 111.527 246.323 108.841C243.682 106.155 242.362 102.468 242.362 97.7785C242.362 94.7283 242.954 92.0423 244.137 89.7205C245.321 87.3531 247.028 85.2134 249.259 83.3013L255.473 87.6718C253.652 89.2652 252.309 90.8814 251.444 92.5203C250.625 94.1137 250.215 95.8437 250.215 97.7103C250.215 100.078 250.898 101.967 252.264 103.378C253.63 104.79 255.542 105.495 258 105.495H287.637V79.6137H295.491ZM269.268 127.757C267.947 127.757 266.855 127.302 265.99 126.392C265.125 125.481 264.692 124.343 264.692 122.977C264.692 121.611 265.125 120.473 265.99 119.563C266.855 118.698 267.947 118.265 269.268 118.265C270.588 118.265 271.681 118.698 272.546 119.563C273.411 120.473 273.843 121.611 273.843 122.977C273.843 124.343 273.411 125.481 272.546 126.392C271.681 127.302 270.588 127.757 269.268 127.757Z"
      />
      <motion.path
        fill="#00A3C4"
        {...urduLogoProps}
        d="M301.667 62.2001H309.52V112.802H301.667V62.2001Z"
      />
      <motion.path
        fill="#00A3C4"
        {...urduLogoProps}
        d="M307.78 120.928C310.603 121.111 312.629 120.564 313.858 119.29C315.133 118.06 315.77 115.943 315.77 112.939V79.6137H323.623V112.939C323.623 117.719 322.326 121.452 319.731 124.138C317.136 126.824 313.562 128.213 309.01 128.304L307.78 120.928Z"
      />
      <motion.path
        fill="#00A3C4"
        {...urduLogoProps}
        d="M329.118 62.2001H336.971V112.802H329.118V62.2001Z"
      />
      <motion.path
        {...urduLogoProps}
        d="M350.282 72.8531C355.609 74.0823 360.252 76.3586 364.213 79.682C368.174 83.0054 371.224 87.1482 373.364 92.1106C375.504 97.0274 376.596 102.513 376.642 108.568C370.086 111.391 362.551 112.802 354.038 112.802C349.577 112.802 345.252 112.37 341.063 111.505V103.993C345.206 104.994 349.554 105.495 354.106 105.495C359.251 105.495 364.031 104.881 368.447 103.651C367.582 97.5964 365.442 92.4748 362.028 88.2864C358.613 84.0525 354.106 81.2754 348.507 79.9552L350.282 72.8531Z"
        fill="#00A3C4"
      />
      <motion.path
        {...urduLogoProps}
        d="M314.407 49.8402C315.317 49.8402 315.772 51.0466 315.772 53.4595C315.772 55.9179 315.317 57.1471 314.407 57.1471H299.406V57.9666C299.406 67.8912 289.914 72.8535 270.929 72.8535C261.733 72.8535 254.631 71.4422 249.623 68.6196C244.661 65.8425 242.18 61.1306 242.18 54.4838C242.18 52.9815 242.294 51.206 242.521 49.1573C242.749 47.0631 243.09 45.1055 243.546 43.2845L251.194 45.06C250.875 46.3347 250.602 47.8826 250.374 49.7036C250.147 51.4791 250.033 53.027 250.033 54.3473C250.033 57.1243 250.693 59.3096 252.013 60.903C253.334 62.5419 255.496 63.7256 258.501 64.454C261.551 65.1824 265.717 65.5466 270.998 65.5466C277.918 65.5466 282.88 64.9548 285.885 63.7711C288.935 62.633 290.46 60.903 290.46 58.5812C290.46 58.0349 290.392 57.5568 290.255 57.1471H289.367C283.995 57.1471 279.966 55.7586 277.28 52.9815L282.743 47.7915C283.472 48.52 284.337 49.0435 285.338 49.3622C286.34 49.6809 287.683 49.8402 289.367 49.8402H314.407Z"
        fill="#00A3C4"
      />
      <motion.path
        {...urduLogoProps}
        d="M335.803 49.84C336.713 49.84 337.168 51.0465 337.168 53.4593C337.168 55.9177 336.713 57.1469 335.803 57.1469H315.043C314.132 57.1469 313.677 55.9177 313.677 53.4593C313.677 51.0465 314.132 49.84 315.043 49.84H320.301V6.54492H328.154V49.84H335.803Z"
        fill="#00A3C4"
      />
      <motion.path
        {...urduLogoProps}
        d="M375.525 49.84V57.1469H336.054C335.144 57.1469 334.688 55.9177 334.688 53.4593C334.688 51.0465 335.144 49.84 336.054 49.84H344.454C342.405 47.0174 341.381 43.7396 341.381 40.0064C341.381 36.592 342.223 33.519 343.907 30.7875C345.592 28.0104 347.891 25.8479 350.805 24.3C353.718 22.7066 356.905 21.9099 360.365 21.9099C364.736 21.9099 369.425 23.1163 374.433 25.5292L370.95 32.0167C366.716 30.1501 363.21 29.2168 360.433 29.2168C358.294 29.2168 356.359 29.6948 354.629 30.6509C352.944 31.5614 351.624 32.8361 350.668 34.4751C349.712 36.114 349.234 37.935 349.234 39.9382C349.234 42.3966 349.94 44.5363 351.351 46.3573C352.808 48.1328 354.743 49.2937 357.155 49.84H375.525Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M145.473 121.4V222.637H132.921V211.176C130.01 215.178 126.326 218.225 121.869 220.317C117.412 222.409 112.501 223.455 107.134 223.455C100.13 223.455 93.8543 221.909 88.3058 218.816C82.7574 215.724 78.3914 211.403 75.2078 205.855C72.1153 200.215 70.569 193.757 70.569 186.481C70.569 179.204 72.1153 172.792 75.2078 167.243C78.3914 161.695 82.7574 157.374 88.3058 154.282C93.8543 151.189 100.13 149.643 107.134 149.643C112.319 149.643 117.094 150.643 121.46 152.644C125.826 154.554 129.464 157.42 132.375 161.24V121.4H145.473ZM108.226 211.994C112.774 211.994 116.912 210.948 120.641 208.856C124.371 206.673 127.281 203.672 129.373 199.852C131.465 195.94 132.512 191.483 132.512 186.481C132.512 181.478 131.465 177.067 129.373 173.246C127.281 169.335 124.371 166.333 120.641 164.241C116.912 162.149 112.774 161.103 108.226 161.103C103.587 161.103 99.4027 162.149 95.6734 164.241C92.0351 166.333 89.1244 169.335 86.9414 173.246C84.8494 177.067 83.8034 181.478 83.8034 186.481C83.8034 191.483 84.8494 195.94 86.9414 199.852C89.1244 203.672 92.0351 206.673 95.6734 208.856C99.4027 210.948 103.587 211.994 108.226 211.994Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M185.673 149.643C195.678 149.643 203.319 152.099 208.594 157.01C213.961 161.922 216.644 169.244 216.644 178.977V222.637H204.228V213.086C202.045 216.451 198.907 219.044 194.814 220.863C190.812 222.591 186.037 223.455 180.488 223.455C172.393 223.455 165.89 221.5 160.978 217.588C156.157 213.677 153.747 208.538 153.747 202.171C153.747 195.804 156.066 190.71 160.705 186.89C165.344 182.979 172.711 181.023 182.808 181.023H203.546V178.431C203.546 172.792 201.909 168.471 198.635 165.469C195.36 162.468 190.539 160.967 184.172 160.967C179.897 160.967 175.713 161.695 171.62 163.15C167.527 164.514 164.07 166.379 161.251 168.744L155.793 158.92C159.523 155.919 163.979 153.645 169.164 152.099C174.349 150.461 179.852 149.643 185.673 149.643ZM182.671 213.359C187.674 213.359 191.995 212.267 195.633 210.084C199.271 207.81 201.909 204.627 203.546 200.534V190.437H183.354C172.257 190.437 166.708 194.167 166.708 201.625C166.708 205.264 168.118 208.129 170.938 210.221C173.757 212.313 177.669 213.359 182.671 213.359Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M239.678 162.468C241.952 158.284 245.317 155.1 249.774 152.917C254.231 150.734 259.643 149.643 266.01 149.643V162.331C265.283 162.24 264.282 162.195 263.009 162.195C255.914 162.195 250.32 164.332 246.227 168.607C242.225 172.792 240.224 178.795 240.224 186.617V222.637H227.126V150.325H239.678V162.468Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M300.28 149.643C310.286 149.643 317.926 152.099 323.202 157.01C328.568 161.922 331.252 169.244 331.252 178.977V222.637H318.836V213.086C316.653 216.451 313.515 219.044 309.422 220.863C305.419 222.591 300.644 223.455 295.096 223.455C287 223.455 280.497 221.5 275.585 217.588C270.764 213.677 268.354 208.538 268.354 202.171C268.354 195.804 270.673 190.71 275.312 186.89C279.951 182.979 287.319 181.023 297.415 181.023H318.154V178.431C318.154 172.792 316.516 168.471 313.242 165.469C309.967 162.468 305.147 160.967 298.78 160.967C294.504 160.967 290.32 161.695 286.227 163.15C282.134 164.514 278.678 166.379 275.858 168.744L270.401 158.92C274.13 155.919 278.587 153.645 283.771 152.099C288.956 150.461 294.459 149.643 300.28 149.643ZM297.279 213.359C302.281 213.359 306.602 212.267 310.24 210.084C313.879 207.81 316.516 204.627 318.154 200.534V190.437H297.961C286.864 190.437 281.316 194.167 281.316 201.625C281.316 205.264 282.725 208.129 285.545 210.221C288.365 212.313 292.276 213.359 297.279 213.359Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M382.914 149.643C389.918 149.643 396.194 151.189 401.743 154.282C407.291 157.374 411.612 161.695 414.704 167.243C417.888 172.792 419.48 179.204 419.48 186.481C419.48 193.757 417.888 200.215 414.704 205.855C411.612 211.403 407.291 215.724 401.743 218.816C396.194 221.909 389.918 223.455 382.914 223.455C377.548 223.455 372.636 222.409 368.179 220.317C363.722 218.225 360.038 215.178 357.128 211.176V222.637H344.576V121.4H357.674V161.24C360.584 157.42 364.223 154.554 368.589 152.644C372.954 150.643 377.73 149.643 382.914 149.643ZM381.823 211.994C386.462 211.994 390.6 210.948 394.239 208.856C397.968 206.673 400.879 203.672 402.971 199.852C405.154 195.94 406.245 191.483 406.245 186.481C406.245 181.478 405.154 177.067 402.971 173.246C400.879 169.335 397.968 166.333 394.239 164.241C390.6 162.149 386.462 161.103 381.823 161.103C377.275 161.103 373.136 162.149 369.407 164.241C365.678 166.333 362.767 169.335 360.675 173.246C358.583 177.067 357.537 181.478 357.537 186.481C357.537 191.483 358.583 195.94 360.675 199.852C362.767 203.672 365.678 206.673 369.407 208.856C373.136 210.948 377.275 211.994 381.823 211.994Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M145.333 36.834C155.339 36.834 162.979 39.2899 168.255 44.2016C173.621 49.1134 176.304 56.4355 176.304 66.168V109.828H163.889V100.277C161.706 103.643 158.568 106.235 154.474 108.054C150.472 109.782 145.697 110.647 140.149 110.647C132.053 110.647 125.55 108.691 120.638 104.78C115.817 100.869 113.407 95.7294 113.407 89.3624C113.407 82.9953 115.726 77.9016 120.365 74.0814C125.004 70.1702 132.372 68.2146 142.468 68.2146H163.206V65.6223C163.206 59.9829 161.569 55.6623 158.295 52.6607C155.02 49.6591 150.199 48.1583 143.832 48.1583C139.557 48.1583 135.373 48.886 131.28 50.3413C127.187 51.7057 123.731 53.5703 120.911 55.9352L115.453 46.1117C119.183 43.1101 123.64 40.8362 128.824 39.2899C134.009 37.6526 139.512 36.834 145.333 36.834ZM142.332 100.55C147.334 100.55 151.655 99.4587 155.293 97.2757C158.931 95.0018 161.569 91.8182 163.206 87.7251V77.6287H143.014C131.917 77.6287 126.368 81.358 126.368 88.8166C126.368 92.4549 127.778 95.3201 130.598 97.4121C133.418 99.5042 137.329 100.55 142.332 100.55Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M189.628 8.59148H202.726V109.828H189.628V8.59148Z"
        fill="#00A3C4"
      />
      <motion.path
        {...enLogoProps}
        d="M216.82 37.5162H229.918V109.828H216.82V37.5162ZM223.369 23.5996C220.822 23.5996 218.685 22.781 216.956 21.1437C215.319 19.5065 214.5 17.5054 214.5 15.1405C214.5 12.7756 215.319 10.7745 216.956 9.13723C218.685 7.40902 220.822 6.54492 223.369 6.54492C225.916 6.54492 228.008 7.36355 229.645 9.00079C231.373 10.5471 232.237 12.5027 232.237 14.8676C232.237 17.3235 231.373 19.4155 229.645 21.1437C228.008 22.781 225.916 23.5996 223.369 23.5996Z"
        fill="#00A3C4"
      />
      <motion.path
        {...leftCurlyProps}
        d="M33.4476 102.361C33.4476 108.021 32.59 112.309 30.8747 115.225C29.1594 117.97 26.5008 120.028 22.8987 121.4C26.5008 122.772 29.1594 124.916 30.8747 127.832C32.59 130.577 33.4476 134.779 33.4476 140.44V209.908C33.4476 223.801 39.9656 230.748 53.0016 230.748H60.463V241.811H52.2297C31.132 241.811 20.5831 231.52 20.5831 210.937V140.182C20.5831 131.263 16.7238 126.803 9.00512 126.803H0V115.74H9.00512C16.7238 115.74 20.5831 111.366 20.5831 102.618V31.8635C20.5831 11.2803 31.132 0.98877 52.2297 0.98877H60.463V12.0522H53.0016C39.9656 12.0522 33.4476 18.999 33.4476 32.8926V102.361Z"
        fill="#00A3C4"
      />
      <motion.path
        {...rightCurlyProps}
        d="M456.601 140.44C456.601 134.779 457.459 130.491 459.174 127.575C460.889 124.831 463.548 122.772 467.15 121.4C463.548 120.028 460.889 117.884 459.174 114.968C457.459 112.224 456.601 108.021 456.601 102.361L456.601 32.8927C456.601 18.9991 450.083 12.0523 437.047 12.0523L429.586 12.0523L429.586 0.988866L437.819 0.988866C458.917 0.988864 469.465 11.2805 469.465 31.8636L469.465 102.618C469.465 111.537 473.325 115.997 481.043 115.997L490.049 115.997L490.049 127.061L481.043 127.061C473.325 127.061 469.465 131.434 469.465 140.182L469.465 210.937C469.465 231.52 458.917 241.812 437.819 241.812L429.586 241.812L429.586 230.748L437.047 230.748C450.083 230.748 456.601 223.801 456.601 209.908L456.601 140.44Z"
        fill="#00A3C4"
      />
    </motion.svg>
  );
};

export default AnimatedLogo;
