import * as React from 'react';
import { PageProps } from 'gatsby';
import { Container, Box } from '@chakra-ui/react';
import Nav from './Nav';
import Footer from './Footer';

const Layout = ({ children }: PageProps) => {
  return (
    <Container maxWidth="container.xl" centerContent position="relative">
      <Nav />
      <Box as="main" pt={[28, null]}>
        {children}
      </Box>
      <Footer />
    </Container>
  );
};

export default Layout;
