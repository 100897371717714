import * as React from 'react';
import { Grid, GridItem, List, ListItem, ListIcon } from '@chakra-ui/react';

// Import Type of the icons
import type { IconType } from '@react-icons/all-files/lib';
// Import ALL the icons
import { SiJavascript } from '@react-icons/all-files/si/SiJavascript';
import { SiTypescript } from '@react-icons/all-files/si/SiTypescript';
import { SiReact } from '@react-icons/all-files/si/SiReact';
import { SiNodeDotJs } from '@react-icons/all-files/si/SiNodeDotJs';
import { SiLaravel } from '@react-icons/all-files/si/SiLaravel';
import { SiNextDotJs } from '@react-icons/all-files/si/SiNextDotJs'
import { SiGatsby } from '@react-icons/all-files/si/SiGatsby'
import { SiEleventy } from '@react-icons/all-files/si/SiEleventy'
import { SiHubspot } from '@react-icons/all-files/si/SiHubspot'
import { SiWordpress } from '@react-icons/all-files/si/SiWordpress'
import { SiTailwindcss } from '@react-icons/all-files/si/SiTailwindcss'
import { SiBootstrap } from '@react-icons/all-files/si/SiBootstrap'
import { SiSass } from '@react-icons/all-files/si/SiSass'
import { SiCss3 } from '@react-icons/all-files/si/SiCss3'

interface Skills {
  name: string;
  icon: IconType;
}

const SkillList = (): JSX.Element => {
  const langSkills: Skills[] = [
    { name: `JavaScript`, icon: SiJavascript },
    { name: `TypeScript`, icon: SiTypescript },
    { name: `React`, icon: SiReact },
    { name: `Node.js`, icon: SiNodeDotJs },
    { name: `Laravel`, icon: SiLaravel },
  ];

  const fwSkills: Skills[] = [
    { name: `Next.js`, icon: SiNextDotJs },
    { name: `Gatsby`, icon: SiGatsby },
    { name: `11ty`, icon: SiEleventy },
    { name: `HubSpot CMS`, icon: SiHubspot },
    { name: `WordPress`, icon: SiWordpress },
  ];

  const cssSkills: Skills[] = [
    { name: `Chakra UI`, icon: SiCss3 },
    { name: `Tailwind`, icon: SiTailwindcss },
    { name: `Bootstrap 5+`, icon: SiBootstrap },
    { name: `Sass / CSS`, icon: SiSass },
  ];

  return (
    <Grid
      templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
      gap={6}
      maxW="md"
    >
      <GridItem>
      {langSkills.map<React.ReactNode>((skill): React.ReactNode => {
        return (
            <List spacing={3} key={skill.name}>
              <ListItem>
                <ListIcon as={skill.icon} color="cyan.600" />
                {skill.name}
              </ListItem>
            </List>
        );
      })}
      </GridItem>
      <GridItem>
      {fwSkills.map<React.ReactNode>((skill): React.ReactNode => {
        return (
            <List spacing={3} key={skill.name}>>
              <ListItem key={skill.name}>
                <ListIcon as={skill.icon} color="cyan.600" />
                {skill.name}
              </ListItem>
            </List>
        );
      })}
      </GridItem>
      <GridItem>
      {cssSkills.map<React.ReactNode>((skill): React.ReactNode => {
        return (
            <List spacing={3} key={skill.name}>
              <ListItem key={skill.name}>
                <ListIcon as={skill.icon} color="cyan.600" />
                {skill.name}
              </ListItem>
            </List>
        );
      })}
      </GridItem>
    </Grid>
  );
};

export default SkillList;
