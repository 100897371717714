import * as React from 'react';
import { Link } from 'gatsby';
import { Flex, Box, Spacer, useColorModeValue } from '@chakra-ui/react';
import { SimpleLogo } from './Logo';
import ColorModeToggle from './ToggleColorMode';

const Nav: React.FC = (): JSX.Element => {
  const logoColor = useColorModeValue('cyan.600', 'cyan.300');

  return (
    <Flex
      width="100%"
      alignItems="center"
      gap="2"
      as="nav"
      p={2}
      position="absolute"
    >
      <Box p="2">
        <Link to="/">
          <SimpleLogo
            color={logoColor}
            w={28}
            h={28}
            focusable={true}
            role="img"
          />
        </Link>
      </Box>
      <Spacer />
      <Box>
        <ColorModeToggle />
      </Box>
    </Flex>
  );
};

export default Nav;
