import * as React from 'react';
import { useColorMode, Button, VisuallyHidden } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

const ColorModeToggle: React.FC = (): JSX.Element => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <header>
      <Button onClick={toggleColorMode} colorScheme="cyan" variant="ghost">
        <VisuallyHidden>
          {' '}
          Toggle
          {colorMode === 'light' ? 'Dark' : 'Light'} Mode
        </VisuallyHidden>
        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
      </Button>
    </header>
  );
};

export default ColorModeToggle;
